import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import config from '../../config';
import LanguageContainer from './LanguageContainer';

const RootContainer = ({ store }) => {
  const theme = {
    colors: {
      primary: '#3F32B1',
      secondary: '#007D8B'
    },
    buttonsPrimary: {
      background: '#3F32B1',
      color: '#fff',
      ':disabled': {
        background: '#DFE0E4',
        ':hover': {
          boxShadow: '0 1px 5px 0 #DFE0E4',
        },
      },
      ':hover': {
        boxShadow: '0 1px 5px 0 #3F32B1',
        opacity: 0.9,
      }
    },
    buttonsSecondary: {
      background: '#007D8B',
      color: '#fff',
      ':disabled': {
        background: '#DFE0E4',
        ':hover': {
          boxShadow: '0 1px 5px 0 #DFE0E4',
        },
      },
      ':hover': {
        boxShadow: '0 1px 5px 0 #007D8B',
        opacity: 0.9,
      }
    },
    buttonsPrimaryOutline: {
      borderColor: '#3F32B1',
      ':disabled': {
        background: '#DFE0E4',
        ':hover': {
          boxShadow: '0 1px 5px 0 #DFE0E4',
        },
      },
      ':hover': {
        boxShadow: '0 1px 5px 0 #3F32B1',
        opacity: 0.9,
      }
    },
    buttonsSecondaryOutline: {
      borderColor: '#007D8B',
      ':disabled': {
        background: '#DFE0E4',
        ':hover': {
          boxShadow: '0 1px 5px 0 #DFE0E4',
        },
      },
      ':hover': {
        boxShadow: '0 1px 5px 0 #007D8B',
        opacity: 0.9,
      }
    },
    icon: {
      color: '#007d8b'
    }
  };

  if (config.isLogisticForce) {
    theme.colors.primary = '#BED000';
    theme.colors.secondary = '#BED000';
    theme.buttonsPrimary.background = '#BED000';
    theme.buttonsPrimary[':hover'].boxShadow = '0 1px 5px 0 #BED000';
    theme.buttonsSecondary.background = '#BED000';
    theme.buttonsSecondary[':hover'].boxShadow = '0 1px 5px 0 #BED000';
    theme.buttonsPrimaryOutline.borderColor = '#BED000';
    theme.buttonsPrimaryOutline[':hover'].boxShadow = '0 1px 5px 0 #BED000';
    theme.buttonsSecondaryOutline.borderColor = '#BED000';
    theme.buttonsSecondaryOutline[':hover'].boxShadow = '0 1px 5px 0 #BED000';
    theme.icon.color = '#BED000';
  }

  if (config.isTerborg) {
    theme.colors.primary = '#1B3C8E';
    theme.colors.secondary = '#50C5D7';
    theme.buttonsPrimary.background = '#1B3C8E';
    theme.buttonsPrimary[':hover'].boxShadow = '0 1px 5px 0 #1B3C8E';
    theme.buttonsSecondary.background = '#50C5D7';
    theme.buttonsSecondary[':hover'].boxShadow = '0 1px 5px 0 #50C5D7';
    theme.buttonsPrimaryOutline.borderColor = '#1B3C8E';
    theme.buttonsPrimaryOutline[':hover'].boxShadow = '0 1px 5px 0 #1B3C8E';
    theme.buttonsSecondaryOutline.borderColor = '#50C5D7';
    theme.buttonsSecondaryOutline[':hover'].boxShadow = '0 1px 5px 0 #50C5D7';
    theme.icon.color = '#50C5D7';
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LanguageContainer store={store} />
      </Provider>
    </ThemeProvider>
  );
};

RootContainer.displayName = 'RootContainer';
RootContainer.propTypes = {
  store: PropTypes.object.isRequired,
};

export default RootContainer;
