import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const WorkersImportPage = loadable(() => import(
  /* webpackChunkName: "workers-import-page-chunk" */
  './WorkersImportPage'
), {
  fallback: (
    <Preloader isDefault={config.isLogisticForce || config.isTerborg} />
  ),
});

export default WorkersImportPage;
